import { useState } from "react";
import { useTranslation } from 'react-i18next';
import {
  Box,
  Card,
  useMediaQuery,
  Dialog,
  CardMedia,
  IconButton,
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Carousel from "../common/Carousel";

import work1 from '../../assets/work1.jpg';
import work2 from '../../assets/work2.jpg';
import work3 from '../../assets/work3.jpg';

import work11 from '../../assets/work1-1.jpg';
import work12 from '../../assets/work1-2.jpg';
import work13 from '../../assets/work1-3.jpg';
import work14 from '../../assets/work1-4.jpg';
import work15 from '../../assets/work1-5.jpg';
import work16 from '../../assets/work1-6.jpg';

import work21 from '../../assets/work2-1.jpg';
import work22 from '../../assets/work2-2.jpg';
import work23 from '../../assets/work2-3.jpg';
import work24 from '../../assets/work2-4.jpg';
import work25 from '../../assets/work2-5.jpg';
import work26 from '../../assets/work2-6.jpg';

import work31 from '../../assets/work3-1.jpg';
import work32 from '../../assets/work3-2.jpg';
import work33 from '../../assets/work3-3.jpg';
import work34 from '../../assets/work3-4.jpg';
import work35 from '../../assets/work3-5.jpg';
import work36 from '../../assets/work3-6.jpg';

import work41 from '../../assets/work41.jpg';
import work42 from '../../assets/work42.jpg';
import work43 from '../../assets/work43.jpg';
import work44 from '../../assets/work44.jpg';
import work45 from '../../assets/work45.jpg';
import work46 from '../../assets/work46.jpg';

import work51 from '../../assets/work51.jpg';
import work52 from '../../assets/work52.jpg';
import work53 from '../../assets/work53.jpg';
import work54 from '../../assets/work54.jpg';
import work55 from '../../assets/work55.jpg';

import work61 from '../../assets/work61.jpg';
import work62 from '../../assets/work62.jpg';
import work63 from '../../assets/work63.jpg';
import work64 from '../../assets/work64.jpg';
import work65 from '../../assets/work65.jpg';
import work66 from '../../assets/work66.jpg';
import work67 from '../../assets/work67.jpg';

import work71 from '../../assets/work71.jpg';
import work72 from '../../assets/work72.jpg';
import work73 from '../../assets/work73.jpg';
import work74 from '../../assets/work74.jpg';
import work75 from '../../assets/work75.jpg';
import work76 from '../../assets/work76.jpg';
import work77 from '../../assets/work77.jpg';
import work78 from '../../assets/work78.jpg';

const Works= () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedWork, setSelectedWork] = useState(null);

  const handleOnClose = () => {
    setSelectedIndex(0);
    setOpenDialog(false);
    setSelectedWork(null);
  }

  const handleSelectWork = (index) => {
    setSelectedWork(index);
    setOpenDialog(true);
  }

  const getCurrentWorkList = () => {
    switch(selectedWork) {
      case 0:
        return work1List;
      case 1:
        return work2List;
      case 2:
        return work3List;
      case 3:
        return work4List;
      case 4:
        return work5List;
      case 5:
        return work6List;
      case 6:
        return work7List;
      default:
        return null;
    }
  }

  const worksList = [
    {
      imageUrl: work1,
      name: `${t('DRAINAGE_SYSTEM')} - Inoxsystem`,
      description: t('WORK_DESCRIPTION_1'),
    },
    {
      imageUrl: work2,
      name: `${t('DRAINAGE_SYSTEM')} - Inoxsystem`,
      description: t('WORK_DESCRIPTION_2'),
    },
    {
      imageUrl: work3,
      name: `${t('DRAINAGE_SYSTEM')} - Inoxsystem`,
      description: t('WORK_DESCRIPTION_3'),
    },
    {
      imageUrl: work41,
      name: `${t('CONTROLLED_AIR_SYSTEM')} - Enershield Air Barriers`,
      description: t('WORK_DESCRIPTION_4'),
    },
    {
      imageUrl: work51,
      name: `${t('COOLING_SYSTEM')} - Standard Tech`,
      description: t('WORK_DESCRIPTION_5'),
    },
    {
      imageUrl: work61,
      name: `${t('COOLING_SYSTEM')} - Standard Tech`,
      description: t('WORK_DESCRIPTION_6'),
    },
    {
      imageUrl: work71,
      name: `${t('COOLING_SYSTEM')} - Standard Tech`,
      description: t('WORK_DESCRIPTION_7'),
    },
  ];

  const work1List = [
    {
      imageUrl: work11,
    },
    {
      imageUrl: work12,
    },
    {
      imageUrl: work13,
    },
    {
      imageUrl: work14,
    },
    {
      imageUrl: work15,
    },
    {
      imageUrl: work16,
    }
  ];

  const work2List = [
    {
      imageUrl: work21,
    },
    {
      imageUrl: work22,
    },
    {
      imageUrl: work23,
    },
    {
      imageUrl: work24,
    },
    {
      imageUrl: work25,
    },
    {
      imageUrl: work26,
    }
  ];

  const work3List = [
    {
      imageUrl: work31,
    },
    {
      imageUrl: work32,
    },
    {
      imageUrl: work33,
    },
    {
      imageUrl: work34,
    },
    {
      imageUrl: work35,
    },
    {
      imageUrl: work36,
    }
  ];

  const work4List = [
    {
      imageUrl: work41,
    },
    {
      imageUrl: work42,
    },
    {
      imageUrl: work43,
    },
    {
      imageUrl: work44,
    },
    {
      imageUrl: work45,
    },
    {
      imageUrl: work46,
    }
  ];

  const work5List = [
    {
      imageUrl: work51,
    },
    {
      imageUrl: work52,
    },
    {
      imageUrl: work53,
    },
    {
      imageUrl: work54,
    },
    {
      imageUrl: work55,
    },
  ];

  const work6List = [
    {
      imageUrl: work61,
    },
    {
      imageUrl: work62,
    },
    {
      imageUrl: work63,
    },
    {
      imageUrl: work64,
    },
    {
      imageUrl: work65,
    },
    {
      imageUrl: work66,
    },
    {
      imageUrl: work67,
    }
  ];

  const work7List = [
    {
      imageUrl: work71,
    },
    {
      imageUrl: work72,
    },
    {
      imageUrl: work73,
    },
    {
      imageUrl: work74,
    },
    {
      imageUrl: work75,
    },
    {
      imageUrl: work76,
    },
    {
      imageUrl: work77,
    },
    {
      imageUrl: work78,
    }
  ];

  return (
    <section className="section bg-light-gray" id="works">
      <div className="container">
        <Box spacing={isMobile ? 4 : 10}>
          <h2 className="mt-0 mb-0 inline-block">
            {t('JOBS')}
          </h2>
          <Box sx={{ mt: '50px'}}>
            <Carousel
              slidesPerView={isMobile ? 1 : 3}
              carouselId='jobs-swiper'
              height={460}
              autoplayEnabled={!openDialog}
            >
              {worksList.map((work, index) => (
                <Card
                  className="relative h-full card"
                  key={index}
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={() => handleSelectWork(index)}
                >
                  <img
                    className="w-full"
                    src={work.imageUrl}
                    alt={work.name}
                  />
                  <Box
                    sx={{
                      backgroundColor: '#3f50b5',
                      px: 4,
                      py: 2,
                      mt: '-6px'
                    }}
                  >
                    <h5
                      className="m-0 text-16 font-bold"
                      style={{ color: '#FFFFFF'}}
                    >
                      {work.name}
                    </h5>
                  </Box>
                  <Box sx={{ px: 4 }}>
                    <p className="mb-4" style={{ marginTop: '16px' }}>
                      {work.description}
                    </p>
                  </Box>
                </Card>
              ))}
          </Carousel>
        </Box>
      </Box>
    </div>
    {openDialog && (
      <Dialog
        open={openDialog}
        onClose={handleOnClose}
        maxWidth='lg'
        sx={{
          '& .MuiPaper-root':{
            backgroundColor: 'transparent',
            boxShadow: 'none',
          }
        }}
      >
        {selectedIndex !== 0 && (
          <IconButton 
            onClick={() => setSelectedIndex(selectedIndex - 1)}
            sx={{
              height: '40px',
              width: '40px',
              borderRadius: '20px',
              backgroundColor: '#000000bf',
              position: 'fixed',
              top: '50%',
              bottom: '50%',
              left: '12px !important',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              '&:hover':{
                backgroundColor: '#4d90fe',
              },
            }}
          >
            <NavigateBeforeIcon
              fontSize='medium'
              sx={{ color: '#ffffff' }}
            />
            </IconButton>
        )}
        <CardMedia
          src={getCurrentWorkList()[selectedIndex]?.imageUrl}
          component="img"
          loading="lazy"
          width={900}
          height={600}
          sx={{
            objectFit: 'contain',
            height: 'fit-content',
            maxHeight: '90vh',
          }}
        />
        {selectedIndex !== getCurrentWorkList()?.length - 1 && (
          <IconButton 
            onClick={() => setSelectedIndex(selectedIndex + 1)}
            sx={{
              height: '40px',
              width: '40px',
              borderRadius: '20px',
              backgroundColor: '#000000bf',
              position: 'fixed',
              top: '50%',
              bottom: '50%',
              right: '12px !important',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              '&:hover':{
                backgroundColor: '#4d90fe',
              },
            }}
          >
            <NavigateNextIcon
              fontSize='medium'
              sx={{ color: '#ffffff' }}
            />
          </IconButton>
        )}
      </Dialog>
    )}
  </section>
  );
};

export default Works;
