import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    es: {
      translation: {
        ABOUT_US: 'Nosotros',
        APPLICABLE_SOLUTIONS: 'Áreas aplicables',
        PRODUCTS: 'Productos',
        JOBS: 'Trabajos',
        INDUSTRIAL_SOLUTIONS: 'Soluciones industriales',
        CONTACT: 'Contacto',
        ABOUT_US_DESCRIPTION_1: 'Bienvenidos a Parnard, una empresa dedicada a la ingeniería, asesoría técnica y venta de soluciones para el drenaje de líquidos, sistemas de refrigeración y productos de aire controlado. Gracias a nuestra alianza estratégica con Inoxsystem SRL, Standard Tech SRL y Enershield Air Barriers, nos hemos convertido en los distribuidores y representantes exclusivos de sus servicios en América Latina, consolidando nuestra posición en el mercado.',
        ABOUT_US_DESCRIPTION_2: 'Nuestra principal fortaleza radica en la capacidad de proporcionar soluciones integrales a clientes de diversos sectores, incluyendo alimentación, farmacéutica, vitivinícola, cosmética e industrial. Trabajamos en estrecha colaboración con nuestros clientes, comprendiendo sus necesidades específicas y diseñando soluciones personalizadas que se adaptan a sus requerimientos.',
        ABOUT_US_ITEM_1: 'Contamos con un equipo altamente capacitado que se dedica a brindar asesoramiento técnico especializado y a ofrecer productos de la más alta calidad. Nos complace poder ayudar a nuestros clientes a optimizar sus sistemas, garantizando la eficiencia y el rendimiento óptimo en sus operaciones.',
        ABOUT_US_ITEM_2: 'En Parnard, entendemos la importancia de contar con sistemas eficaces, innovadores y confiables en las industrias que servimos. Ofrecemos a nuestros clientes una experiencia segura, abordando sus necesidades varias a través de un único proveedor de confianza.',
        QUALITY_SOLUTIONS: 'SOLUCIONES DE CALIDAD',
        QUALITY_SOLUTIONS_DESCRIPTION: 'Se trabaja con productos en acero inoxidable AISI 304 o 316, sistemas de refrigeración industrial de alta tecnología y soluciones de aire controlado y comprimido.',
        CUSTOMIZED_PRODUCTION: 'PRODUCCIÓN PERSONALIZADA',
        CUSTOMIZED_PRODUCTION_DESCRIPTION: 'Nuestro enfoque en el proceso de elaboración, respaldado por un equipo especializado en diseño constructivo, garantiza la velocidad y eficiencia en la fabricación.',
        DELIVERY_LOGISTICS: 'LOGÍSTICA DE ENTREGA',
        DELIVERY_LOGISTICS_DESCRIPTION: 'Gestionamos y coordinamos sus envíos con fábrica para realizar una pronta entrega.',
        EUROPEAN_QUALITY: 'CALIDAD EUROPEA',
        EUROPEAN_QUALITY_DESCRIPTION: 'Nuestros productos son diseñados y fabricados bajo los estándares de la más alta calidad europea.',
        INDUSTRIAL_SOLUTIONS_1: 'Primeros acercamientos y estudios de sus proyectos.',
        INDUSTRIAL_SOLUTIONS_2: 'Materialización de sus ideas (croquis, dibujos técnicos, detalles constructivos).',
        INDUSTRIAL_SOLUTIONS_3: 'Elección de los productos más apropiados para cada necesidad específica.',
        INDUSTRIAL_SOLUTIONS_4: 'Elaboración y presentación de fichas técnicas a clientes.',
        INDUSTRIAL_SOLUTIONS_5: 'Gestión, coordinación en la entrega de sus productos y servicio postventa.',
        INDUSTRIAL_SOLUTIONS_6: 'Inspección bajo personal capacitado para garantizar el correcto funcionamiento de sus sistemas.',
        INDUSTRIAL_SOLUTIONS_7: 'Productos fabricados en acero inoxidable AISI 304/316, soluciones de refrigeración industrial de alta tecnología y sistemas de aire controlado.',
        INDUSTRIAL_SOLUTIONS_8: 'Proyectos respaldados por empresas europeas de una sólida trayectoria y experiencia en el mercado .',
        INDUSTRIAL_SOLUTIONS_9: 'Nuestros productos cumplen con los estándares de calidad del mercado.',
        INDUSTRIAL_SOLUTIONS_10: 'Artículos diseñados y fabricados exclusivamente en Europa.',
        INDUSTRIAL_SOLUTIONS_11: 'Funcionamiento de sistemas garantizados.',
        INDUSTRIAL_SOLUTIONS_12: 'Capacitaciones constantes a cargo de técnicos experimentados en productos de Inoxsystem Srl, Standard Tech Srl y Enershield Air Barriers.',
        HOMEPAGE_TITLE: 'Soluciones integrales adaptadas a sus necesidades',
        HOMEPAGE_DESCRIPTION: 'Con nuestra inserción en el mercado industrial logramos ofrecer componentes, sistemas, productos e ingeniería para solventar sus proyectos.',
        DRAINAGE_SYSTEM: 'Sistema de drenaje',
        COOLING_SYSTEM: 'Sistema de refrigeración',
        CONTROLLED_AIR_SYSTEM: 'Sistema de aire controlado',
        GENERAL_CATALOGUE: 'Catálogo general',
        WORK_DESCRIPTION_1: 'Proyecto realizado para la industria láctea. Se diseñó y ejecutó el sistema de drenaje más la instalación del piso industrial para el área de procesamiento y embotellado de leche.',
        WORK_DESCRIPTION_2: 'Proyecto realizado para la industria láctea. Se diseñó y ejecutó el sistema de drenaje más la provisión del piso industrial para el área de pasteurización y procesamiento de leche.',
        WORK_DESCRIPTION_3: 'Proyecto realizado para la industria cárnica. Se diseñó y ejecutó el sistema de drenaje más la provisión del piso industrial para el área de procesamiento de carnes y embutidos.',
        WORK_DESCRIPTION_4: 'Proyectos realizados para diferentes industrias. Diseñamos e instalamos barreras de aire en accesos críticos, a medida de cada cliente.',
        WORK_DESCRIPTION_5: 'Proyecto realizado para la industria cárnica. Se diseñó y ejecutó un sistema de refrigeración y acondicionamiento para la carne en sus diferentes zonas de procesos.',
        WORK_DESCRIPTION_6: 'Proyecto realizado para la industria panificadora. Se diseñó y ejecutó un sistema de acondicionamiento para las diferentes áreas del procesamiento del pan. ',
        WORK_DESCRIPTION_7: 'Proyecto realizado para la industria láctea. Se diseñó y ejecutó un sistema de acondicionamiento y maduración para quesos con un alto nivel de higienización.',
        INDUSTRIAL_SOLUTION_SUBTITLE_1: 'Asesoramiento Profesional',
        INDUSTRIAL_SOLUTION_SUBTITLE_2: 'Distribuidores y Representantes oficiales',
        ADDRESS: 'Dirección',
        PHONE: 'Teléfono',
        NAME: 'Nombre',
        SUBJECT: 'Asunto',
        MESSAGE: 'Mensaje',
        SEND_MESSAGE: 'Enviar mensaje',
        ABOUT_US_FOOTER: 'Parnard es una empresa dedicada a la ingeniería, asesoría técnica y venta de soluciones para el drenaje de líquidos, sistemas de refrigeración industrial y productos de aire controlado. Brindando así, soluciones con la mejor tecnología del mercado a través de una alianza con tres de las empresas europeas más importantes del rubro, Inoxsystem Srl, Standard Tech SRL y Enershield Air Barriers.',
        DOWNLOAD_PDF: 'Descargar PDF',
        DRAINS: 'Sumideros',
        GUTTERS: 'Canaletas',
        GRATES: 'Rejillas',
        INSPECTION_COVERS: 'Tapas de Inspección',
        FINISHING_PROFILES: 'Perfiles de Terminación',
        ACCESSORIES: 'Accesorios',
        SANITIZATION: 'Acondicionamiento higienizable',
        CLEAN_ROOMS: 'Salas blancas',
        DAIRY_PRODUCTS: 'Maduración productos lácteos',
        SALAMI_CURING: 'Maduración de embutidos',
        CURED_RAW_HAM: 'Maduración de jamón crudo',
        AIR_BARRIER_TECHNOLOGY: 'Tecnología de Barrera de Aire',
        DESTRATIFICATION_FANS: 'Ventiladores de Desestratificación',
        DRYING_SYSTEM: 'Sistemas de Secado',
        RAPID_ACTION_DOORS: 'Puertas de Acción Rápida',
        EVERYDAT_USES: 'Usos cotidianos ',
        WINERIES: 'Bodegas y Líquidos varios',
        PROFESSIONAL_KITCHENS: 'Cocinas profesionales',
        COLD_STORAGE: 'Frígorificos y Carnicerías',
        BREWERIES: 'Cervecerías ',
        DAIRY_INDUSTRY: 'Industria Láctea',
        MEAT_INDUSTRY: 'Industria Carnica',
        BAKING_INDUSTRY: 'Industria Panificadora',
        MANI_INDUSTRY: 'Industria del Mani',
        AIRPORTS: 'Aeropuertos',
        FOOD_INDUSTRY: 'Industria Alimentaria',
        PHARMACEUTICAL_INDUSTRY: 'Industria Farmacéutica',
        MANUFACTURING_INDUSTRY: 'Industria Manufacturera',
        REATIL: 'Retail',
        WAREHOUSE: 'Almacén y Distribución',
        PDF_1: 'Barreras de Aire Catálogo General.pdf',
        PDF_2: 'Sistemas de Drenaje Catálogo General.pdf',
        PDF_3: 'Sistemas de Refrigeración Catálogo General.pdf',
        PEANUT_PASTE_PREPARATION: 'Elaboración de pasta de maní',
        EMAIL_SENT_SUCCESS: 'El email se envió satisfactoriamente',
        EMAIL_SENT_ERROR: 'Error al intentar enviar el email',
        SENDING: 'Enviando...',
      },
    },
    en: {
      translation: {
        ABOUT_US: 'About us',
        APPLICABLE_SOLUTIONS: 'Applicable areas',
        PRODUCTS: 'Products',
        JOBS: 'Jobs',
        INDUSTRIAL_SOLUTIONS: 'Industrial solutions',
        CONTACT: 'Contact',
        ABOUT_US_DESCRIPTION_1: 'Welcome to Parnard, a company dedicated to engineering, technical consulting, and sales of solutions for liquid drainage, refrigeration systems, and controlled air products. Thanks to our strategic alliance with Inoxsystem SRL, Standard Tech SRL, and Enershield Air Barriers, we have become the exclusive distributors and representatives of their services in Latin America, consolidating our position in the market.',
        ABOUT_US_DESCRIPTION_2: 'Our main strength lies in our ability to provide integral solutions to clients from diverse sectors, including food, pharmaceutical, wine, cosmetics and industrial. We work in close collaboration with our clients, understanding their specific needs and designing customized solutions that adapt to their requirements.',
        ABOUT_US_ITEM_1: 'We have a highly trained team that is dedicated to providing specialized technical advice and offering the highest quality products. We are pleased to be able to help our clients optimize their systems, ensuring efficiency and optimal performance in their operations.',
        ABOUT_US_ITEM_2: 'At Parnard, we understand the importance of having effective, innovative, and reliable systems in the industries we serve. We offer our clients a secure experience, addressing their various needs through a single trusted provider.',
        QUALITY_SOLUTIONS: 'QUALITY SOLUTIONS',
        QUALITY_SOLUTIONS_DESCRIPTION: 'We work with AISI 304 or 316 stainless steel products, high-tech industrial refrigeration systems and controlled and compressed air solutions.',
        CUSTOMIZED_PRODUCTION: 'CUSTOMIZED PRODUCTION',
        CUSTOMIZED_PRODUCTION_DESCRIPTION: 'Our focus on the manufacturing process, backed by a team specialized in constructive design, ensures speed and efficiency in production.',
        DELIVERY_LOGISTICS: 'DELIVERY LOGISTICS',
        DELIVERY_LOGISTICS_DESCRIPTION: 'We manage and coordinate your shipments with the factory to ensure prompt delivery.',
        EUROPEAN_QUALITY: 'EUROPEAN QUALITY',
        EUROPEAN_QUALITY_DESCRIPTION: 'Our products are designed and manufactured under the highest European quality standards.',
        INDUSTRIAL_SOLUTIONS_1: 'Initial approaches and studies of your projects.',
        INDUSTRIAL_SOLUTIONS_2: 'Materialization of your ideas (sketches, technical drawings, constructive details).',
        INDUSTRIAL_SOLUTIONS_3: 'Selection of the most appropriate products for each specific need.',
        INDUSTRIAL_SOLUTIONS_4: 'Preparation and presentation of technical sheets to clients.',
        INDUSTRIAL_SOLUTIONS_5: 'Management, coordination in the delivery of your products and after-sales service.',
        INDUSTRIAL_SOLUTIONS_6: 'Inspection by trained personnel to ensure the proper functioning of your systems.',
        INDUSTRIAL_SOLUTIONS_7: 'Products manufactured in AISI 304/316 stainless steel, high-tech industrial refrigeration solutions, and controlled air systems.',
        INDUSTRIAL_SOLUTIONS_8: 'Projects backed by European companies with a solid track record and experience in the market.',
        INDUSTRIAL_SOLUTIONS_9: 'Our products comply with the quality standards of the market.',
        INDUSTRIAL_SOLUTIONS_10: 'Items designed and manufactured exclusively in Europe.',
        INDUSTRIAL_SOLUTIONS_11: 'Guaranteed system operation.',
        INDUSTRIAL_SOLUTIONS_12: 'Constant training provided by experienced technicians in products from Inoxsystem Srl, Standard Tech Srl, and Enershield Air Barriers.',
        HOMEPAGE_TITLE: 'Integral solutions tailored to your needs',
        HOMEPAGE_DESCRIPTION: 'With our entry into the market, we manage to offer components, systems, products, and engineering to solve your projects.',
        DRAINAGE_SYSTEM: 'Drainage System',
        COOLING_SYSTEM: 'Refrigeration System',
        CONTROLLED_AIR_SYSTEM: 'Controlled Air System',
        GENERAL_CATALOGUE: 'General Catalogue',
        WORK_DESCRIPTION_1: 'Project carried out for the dairy industry. The drainage system was designed and implemented, along with the installation of the industrial floor for the milk processing and bottling area.',
        WORK_DESCRIPTION_2: 'Project carried out for the dairy industry. The drainage system was designed and implemented, along with the provision of the industrial floor for the pasteurization and milk processing area.',
        WORK_DESCRIPTION_3: 'Project carried out for the meat industry. The drainage system was designed and implemented, along with the provision of the industrial floor for the meat processing and sausage-making area.',
        WORK_DESCRIPTION_4: 'Projects carried out for different industries. We design and install air barriers in critical accesses, customised for each client.',
        WORK_DESCRIPTION_5: 'Project carried out for the meat industry. We designed and implemented a refrigeration and conditioning system for beef and pork in their distinct processing zones.',
        WORK_DESCRIPTION_6: 'Project carried out for the bakery industry. A conditioning system was designed and implemented for the different areas of bread processing.',
        WORK_DESCRIPTION_7: 'Project carried out for the dairy industry. A system was designed and implemented for the conditioning and maturing system for cheeses with a high level of sanitisation.',
        INDUSTRIAL_SOLUTION_SUBTITLE_1: 'Professional Consulting',
        INDUSTRIAL_SOLUTION_SUBTITLE_2: 'Official Distributors and Representatives',
        ADDRESS: 'Address',
        PHONE: 'Phone',
        NAME: 'Name',
        SUBJECT: 'Subject',
        MESSAGE: 'Message',
        SEND_MESSAGE: 'Send message',
        ABOUT_US_FOOTER: 'Parnard is a company dedicated to engineering, technical consulting, and the sale of solutions for liquid drainage, industrial refrigeration systems, and controlled air products. In doing so, they provide solutions with the best technology on the market through a partnership with three of the most important European companies in the sector, Inoxsystem Srl, Standard Tech SRL, and Enershield Air Barriers.',
        DOWNLOAD_PDF: 'Download PDF',
        DRAINS: 'Drains',
        GUTTERS: 'Gutters',
        GRATES: 'Grates',
        INSPECTION_COVERS: 'Inspection Covers',
        FINISHING_PROFILES: 'Finishing Profiles',
        ACCESSORIES: 'Accessories',
        SANITIZATION: 'Sanitization air - conditioning',
        CLEAN_ROOMS: 'Clean Rooms',
        DAIRY_PRODUCTS: 'Dairy products rippening',
        SALAMI_CURING: 'Curing of salami',
        CURED_RAW_HAM: 'Curing of raw ham',
        AIR_BARRIER_TECHNOLOGY: 'Air Barrier Technology',
        DESTRATIFICATION_FANS: 'Destratification Fans',
        DRYING_SYSTEM: 'Drying Systems',
        RAPID_ACTION_DOORS: 'Rapid Action Doors',
        EVERYDAT_USES: 'Everyday Uses',
        WINERIES: 'Wineries and Various Liquids',
        PROFESSIONAL_KITCHENS: 'Professional Kitchens',
        COLD_STORAGE: 'Cold Storage and Butcheries',
        BREWERIES: 'Breweries',
        DAIRY_INDUSTRY: 'Dairy Industry',
        MEAT_INDUSTRY: 'Meat Industry',
        BAKING_INDUSTRY: 'Baking Industry',
        MANI_INDUSTRY: 'Peanut Industry',
        AIRPORTS: 'Airports',
        FOOD_INDUSTRY: 'Food Industry',
        PHARMACEUTICAL_INDUSTRY: 'Pharmaceutical Industry',
        MANUFACTURING_INDUSTRY: 'Manufacturing Industry',
        REATIL: 'Retail',
        WAREHOUSE: 'Warehouse and Distribution',
        PDF_1: 'Air Barriers General Catalog.pdf',
        PDF_2: 'Drainage Systems General Catalog.pdf',
        PDF_3: 'Refrigeration Systems General Catalog.pdf',
        PEANUT_PASTE_PREPARATION: 'Preparation of peanut paste',
        EMAIL_SENT_SUCCESS: 'Email sent successfully!',
        EMAIL_SENT_ERROR: 'Error when trying to send the email',
        SENDING: 'Sending...',
      },
    },
  },
  lng: 'es', // Idioma por defecto
  fallbackLng: 'en', // Idioma de respaldo
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
