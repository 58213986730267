import React, { useState, useRef } from "react";
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  useMediaQuery,
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import { useTheme } from '@mui/material/styles';
import emailjs from '@emailjs/browser';

const Contact = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const form = useRef();

  const { t } = useTranslation();

  const { register, handleSubmit, watch, reset } = useForm({
    mode: 'onChange'
  });
  
  const [snackbarOpenSuccess, setSnackbarOpenSuccess] = useState(false);
  const [snackbarOpenError, setSnackbarOpenError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formKey, setFormKey] = useState(0);


  const sendEmail = (data, e) => {
    e.preventDefault();
    setIsLoading(true);

    emailjs.sendForm('service_r9a8pyv', 'template_jerroy3', form.current, '0ei1hk0cYZMCgEwAV')
      .then(() => {
        form.current.reset();
        reset();
        setFormKey(prevKey => prevKey + 1); 
        setSnackbarOpenSuccess(true); 
        setIsLoading(false);
      }, () => {
        setSnackbarOpenError(true);
      });
  };

  const allFieldsFilled = watch('name') && watch('email') && watch('subject') && watch('message');

  return (
    <section className="section bg-light-gray" id="contact">
      <div className="container">
        <Box spacing={isMobile ? 4 : 10}>
          <h2 className="mt-0 mb-0 inline-block">
            {t('CONTACT')}
          </h2>
          <Grid container spacing={2} sx={{ mt: '50px' }}>
            <Grid item xs={12} sm={6}>
              <Card>
                <CardContent sx={{ p: 3 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Box
                      sx={{
                        display: 'flex',
                        p: 1,
                        border: '2px dotted #3f51b5',
                        borderRadius: '50%',
                        width: '48px',
                        height: '48px',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <LocationOnOutlinedIcon sx={{ color: '#3f51b5', width: '24px', height: '24px' }} />
                    </Box>
                    <Typography sx={{ color: '#666 !important', fontSize: '20px !important', fontWeight: 500, mt: 1.4 }}>
                      {t('ADDRESS')}
                    </Typography>
                    <Typography sx={{ color: '#000 !important', mt: 1.4, fontSize: '14px !important' }}>
                      Malvinas Argentinas 255 - (1406) CABA
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Card>
                <CardContent sx={{ p: 3 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Box
                      sx={{
                        display: 'flex',
                        p: 1,
                        border: '2px dotted #3f51b5',
                        borderRadius: '50%',
                        width: '48px',
                        height: '48px',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <EmailOutlinedIcon sx={{ color: '#3f51b5', width: '24px', height: '24px' }} />
                    </Box>
                    <Typography sx={{ color: '#666 !important', fontSize: '20px !important', fontWeight: 500, mt: 1.4 }}>
                      Email
                    </Typography>
                    <Typography sx={{ color: '#000 !important', mt: 1.4, fontSize: '14px !important' }}>
                      info@parnard.com
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Card>
                <CardContent sx={{ p: 3 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Box
                      sx={{
                        display: 'flex',
                        p: 1,
                        border: '2px dotted #3f51b5',
                        borderRadius: '50%',
                        width: '48px',
                        height: '48px',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <PhoneOutlinedIcon sx={{ color: '#3f51b5', width: '24px', height: '24px' }} />
                    </Box>
                    <Typography sx={{ color: '#666 !important', fontSize: '20px !important', fontWeight: 500, mt: 1.4 }}>
                      {t('PHONE')}
                    </Typography>
                    <Typography sx={{ color: '#000 !important', mt: 1.4, fontSize: '14px !important' }}>
                      +54 9 11 3654-1997
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', width: '100%', mt: 3 }}>
            <form
              key={formKey}
              ref={form}
              onSubmit={handleSubmit(sendEmail)}
              style={{ width: '100%'}}
            >
              <Card sx={{ width: '100%' }}>
                <CardContent sx={{ p: 4 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name='name'
                        variant="standard"
                        label={t('NAME')}
                        {...register('name', { required: true })}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name='email'
                        variant="standard"
                        label="Email"
                        {...register('email', { required: true })}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1.4 }}>
                      <TextField
                        name='subject'
                        variant="standard"
                        label={t('SUBJECT')}
                        {...register('subject', { required: true })}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1.4 }}>
                      <TextField
                        name='message'
                        variant="standard"
                        label={t('MESSAGE')}
                        {...register('message', { required: true })}
                        fullWidth
                        multiline
                      />
                    </Grid>
                  </Grid>
                  <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', mt: 4 }}>
                    <Button
                      variant="contained"
                      type="submit" 
                      disabled={!allFieldsFilled || isLoading}
                    >
                      {isLoading ? 'SENDING....' : t('SEND_MESSAGE')}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </form>
            {snackbarOpenSuccess && (
              <Snackbar
                open={snackbarOpenSuccess}
                autoHideDuration={4000}
                onClose={() => setSnackbarOpenSuccess(false)}
              >
                <Alert
                  onClose={() => setSnackbarOpenSuccess(false)}
                  severity="success"
                  variant="filled"
                  elevation={6}
                  sx={{ width: '100%' }}
                >
                  {t('EMAIL_SENT_SUCCESS')}
                </Alert>
              </Snackbar>
            )}
            {snackbarOpenError && (
              <Snackbar
                open={snackbarOpenError}
                autoHideDuration={4000}
                onClose={() => setSnackbarOpenError(false)}
              >
                <Alert
                  onClose={() => setSnackbarOpenError(false)}
                  severity="error"
                  variant="filled"
                  elevation={6}
                  sx={{ width: '100%' }}
                >
                  {t('EMAIL_SENT_ERROR')}
                </Alert>
              </Snackbar>
            )}
          </Box>
        </Box>
      </div>
    </section>
  );
};

export default Contact;
