import React, {useEffect} from "react";
import {
  Link,
} from "@mui/material";
import { scrollTo } from "../../utils";

const ScrollTo = ({ to, onScroll, children }) => {

  return (
    <Link
      href={`#${to}`}
      onClick={(e) => {
        e.preventDefault();
        scrollTo(to);
        if (onScroll) {
          onScroll(e);
        }
      }}
      sx={{
        ':hover': {
          backgroundColor: '#3f50b5 !important',
          color: '#FFFFFF !important'
        }
      }}
    >
      {children}
    </Link>
  );
};

export default ScrollTo;
